import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Nav from "../components/nav/nav"
import HeroContent from "../components/hero-content/hero-content"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import HeroPlain from "../components/hero-plain/hero-plain"
import Feature from "../components/feature/feature"
import ImageWithText from "../components/image-with-text/image-with-text"

const notFoundPage = ({ data, location, pageContext }) => {
  const pageData = data.tideItemForNotFoundPage?.childItems

  const footerColumns = data.allTideItemForFooterColumn.edges

  const navigation = data.allTideItemForNavigation.edges[0]
  const navigationItems = data.allTideItemForNavigationItem

  const secondaryNavigationItems = data.allTideItemForSecondaryNavigation.edges[0].node.childItems
  const secondaryFooterItems = data.allTideItemForSecondaryFooter.edges[0].node.childItems
  const footerContact = data.allTideItemForFooter.edges[0].node.content.general

  const getRecursiveChildren = (navigation, navigationItems) => {
    return {
      ...navigation.node,
      children: navigationItems.edges
        .filter(e => e.node.parentNodeId === navigation.node.id)
        ?.map(x => getRecursiveChildren(x, navigationItems)),
    }
  }

  let navItems = getRecursiveChildren(navigation, navigationItems)
  let footerItemsTop = footerColumns.map(c => getRecursiveChildren(c, navigationItems))

  return (
    <Layout>
      <Helmet>
        <link rel="icon" href="/icons/favicon/favicon-32x32.png" />
        <link rel="icon" href="/icons/favicon/favicon-16x16.png" />
        <link rel="icon" href="/icons/favicon/favicon.ico" />
        <link rel="icon" href="/icons/favicon/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest/site.webmanifest" />
        <link rel="mask-icon" href="/icons/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c"></meta>
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <Nav items={navItems} initallsOpen={false} location={location} secondaryNavigationItems={secondaryNavigationItems}></Nav>
      {pageData?.map((p, index) => {
        switch (p.templateName) {
          case "HeroContent":
            return (
              <HeroContent
                key={index}
                title={p.content.general.title}
                description={p.content.general.description}
                image={p.content.general.image}
              ></HeroContent>
            )
          case "HeroPlain":
            return <HeroPlain title={p.content.general.title} description={p.content.general.description}></HeroPlain>
          case "Feature":
            return (
              <Feature
                label={p.content.general.label}
                title={p.content.general.title}
                description={p.content.general.description}
                buttonTitle={p.content.general.buttonText}
                buttonLink={p.content.general.buttonLink}
                img={p.content.general.image}
                reverse={p.content.general.reverse}
              ></Feature>
            )
          case "ImageWithText":
            return (
              <ImageWithText
                key={index}
                title={p.content.general.title}
                image={p.content.general.image}
                description={p.content.general.description}
                accordionData={p.childItems}
                hasBackground={p.content.general.hasBackground}
                reverse={p.content.general.reverse}
                link={p.content.general.link}
                linkText={p.content.general.linkText}
                path={p.content.general.path}
              ></ImageWithText>
            )
          default:
            return <div></div>
        }
      })}
      <Footer
        key={footerItemsTop.id}
        itemsTop={footerItemsTop}
        secondaryFooterItems={secondaryFooterItems}
        contact={footerContact}
      ></Footer>
    </Layout>
  )
}

export default notFoundPage

export const notFoundPageQuery = graphql`
  query notFoundPage($id: String!, $language: String!) {
    tideItemForNotFoundPage(id: { eq: $id }, language: { eq: $language }) {
      id
      language
      childItems {
        ... on TideItemForImageWithText {
          templateName
          language
          content {
            general {
              image {
                url
              }
              title
              description
              reverse
              hasBackground
              link
              linkText
              path
            }
          }
          childItems {
            ... on TideItemForAccordion {
              id
              name
              templateName
              language
              childItems {
                ... on TideItemForAccordionItem {
                  id
                  name
                  templateName
                  language
                  content {
                    general {
                      description
                      title
                      link
                      buttonText
                    }
                  }
                }
              }
            }
          }
        }
        ... on TideItemForFeature {
          language
          name
          templateName
          id
          content {
            general {
              buttonLink
              buttonText
              description
              label
              title
              image {
                url
              }
              reverse
            }
          }
        }
        ... on TideItemForHeroPlain {
          id
          language
          name
          templateName
          content {
            general {
              description
              title
            }
          }
        }
        ... on TideItemForHeroContent {
          id
          name
          language
          templateName
          content {
            general {
              description
              image {
                url
              }
              title
            }
          }
        }
      }
    }
    allTideItemForSecondaryNavigation {
      edges {
        node {
          childItems {
            ... on TideItemForNavigationItem {
              id
              name
              language
              templateName
              content {
                general {
                  path
                  title
                  description
                }
              }
            }
          }
        }
      }
    }
    allTideItemForNavigation {
      edges {
        node {
          id
        }
      }
    }
    allTideItemForNavigationItem {
      edges {
        node {
          id
          parentNodeId
          content {
            general {
              description
              isCtaBlock
              title
              path
            }
            cta {
              ctaText
            }
          }
        }
      }
    }
    allTideItemForFooterColumn {
      edges {
        node {
          id
        }
      }
    }
    tideItemForFooterContact {
      content {
        gatsby {
          gatsby
        }
      }
      parentNodeId
    }
    allTideItemForFooter {
      edges {
        node {
          templateName
          name
          language
          id
          content {
            general {
              email
              phone
            }
          }
        }
      }
    }
    allTideItemForSecondaryFooter {
      edges {
        node {
          childItems {
            ... on TideItemForNavigationItem {
              id
              name
              templateName
              language
              content {
                general {
                  path
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
